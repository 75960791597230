
import './App.css';
import Bind from './Components/Binder';
import { useState } from 'react';
import axios from 'axios';
import GeneratedImages from './Components/GeneratedImage';



function App() {


  const [serverResponse,setServerResponce]=useState(null);
  

  const sendRequest=(data)=>{
    setServerResponce(null);
   const  jString={"input":data[1],"Style":data[0]};
    axios.post('https://api.vectora.space/api/data', jString, {
      withCredentials: true
    }) // Replace with your API URL
    .then((response) => {
      console.log('POST request successful', response.data);
      setServerResponce(response);
   
     
   
    })
    .catch((error) => {
      console.error('Error sending POST request', error);
      setServerResponce("Failed");
      window.alert(error.response.data.message);
      
    });

  }




    return(
    <div className = "App" >
    <   div className="">
          <Bind inputData={sendRequest} response={serverResponse}/>
        <div className="output">
    </div>
    
    {serverResponse !== "Failed" && serverResponse !== null && <GeneratedImages images={serverResponse.data.urls} />}
  
    </div>

   
         </div >
    );
}

export default App;