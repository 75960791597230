import React, { useState } from 'react';
import ImageList from './ImageList';
import FullSizeImage from './FullImage';

const GeneratedImages = (imageArray) => {
  const [selectedImage, setSelectedImage] = useState({fullSizeURL:imageArray.images[0]});
  console.log(imageArray);
 

  const images = imageArray.images.map(url => ({
    thumbnailURL: url,
    fullSizeURL: url
  }));

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleDownload = (selectedImage) => { 

  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="w-auto flex justify-center items-center ">
    <div className="flex w-3/4">
      <div className="w-1/6">
        <ImageList images={images} onImageClick={handleImageClick} />
      </div>
      <div className="w-5/6">
        {selectedImage && (
          <FullSizeImage
            image={selectedImage}
            onDownload={handleDownload}
            onClose={handleClose}
          />
        )}
      </div>
    </div>
    </div>
  );
};
export default GeneratedImages;
