import React from "react";

const CustomRadio = ({ id, label, imageSrc, selected, onChange }) => {
    return (<label className = { `relative cursor-pointer  w-80  rounded ${selected ? 'bg-black transform bg-gradient-to-r from-purple-500 to-fuchsia-500 duration-1000 ease-in-out border-b-2' : 'transform hover:scale-105 duration-500 ease-in-out'}` } >
        <input type = "radio"
        className = "hidden"
        id = { id }
        checked = { selected }
        onChange = { () => onChange(id)
        }
        /> <div className = { `h-52 flex flex-col items-center p-3 m-4 rounded-md ${selected ? 'scale-100 duration-500 ease-in-out' : ' transform hover:shadow-lg hover:scale-105 duration-500 ease-in-out'}` } >
        <div className = " w-80" >
        < img src = { imageSrc }
        alt = { label }
        className = { `w-80 h-52 m-0 -mt-8 ${selected ? '' : ' hover:shadow-xl hover:shadow-slate-300/30  duration-500 ease-in-out' }` }
        /> </div> <span className={ `${selected ? 'text-white font-bold' : 'text-zinc-400 font-bold '} m-2` } > { label } </span>



        </div> 
        </label >
    );
};

export default CustomRadio;