import GoogleLogin from "react-google-login";
import React from "react";


import { GoogleOAuthProvider } from '@react-oauth/google';


const Google=()=>{

<GoogleOAuthProvider clientId="<your_client_id>">...</GoogleOAuthProvider>;


<GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>;

}

export default Google;