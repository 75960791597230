import React, { useState } from 'react';



const InputComponent = ({sendInput}) => {
  
  

  const [inputValue, setInputValue] = useState('');
  const [myunderLine,setMyUnderLine]=useState(false);

  const handleMouseOverMy=()=>{
    setMyUnderLine(true);
  
  };
  const handleMouseOutMy=()=>{
    setMyUnderLine(false);
  
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    
    sendInput(event.target.value);
  };

  return (
    <div className="mt-4 flex flex-col items-center justify-center "  onMouseOver={handleMouseOverMy} onMouseOut={handleMouseOutMy}>
    <label className={`block mb-2 text-2xl m-2 p-6 ${myunderLine ? 'animate-text bg-gradient-to-r from-teal-400 via-purple-500 to-fuchsia-500 bg-clip-text text-transparent text-2xl font-bold ' : 'text-white'}`}>
    Enter the prompt
    </label>
    <div className="w-full md:w-96  rounded-md flex items-center justify-center">
      <input
        className=" border-2 text-white border-zinc-700 outline-2 rounded-md min-w-full py-3 px-8 leading-tight focus:outline-none focus:border-purple-600 bg-zinc-700"
        type="text"
        placeholder="Grocery Store"
        value={inputValue}
        onChange={handleInputChange}
      />
    </div>
  </div>
  );
};

export default InputComponent;
