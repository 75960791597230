import React from "react";

const Description=()=>{
    return(<div>
         <div class="mt-5">
        <img src="./images/logo.png" alt="Logo" className="mx-auto object-cover w-96"></img>
        </div>
        <p className=" text-zinc-400 text-xl m-2">Create AI Generated Illustrations From Your Imagination</p>
       


    </div>)
}
export default Description;