import React from "react";
import { useState } from "react";
import CustomRadio from "./CustomRadio";

import {
	Tabs,
	TabsHeader,
	TabsBody,
	Tab,
	TabPanel,
  } from "@material-tailwind/react";

 const  MyTabs=({sendStyle})=> {

	const [underLine,setUnderLine]=useState(false);



	const handleMouseOver=()=>{
	  setUnderLine(true);
  
	};
	const handleMouseOut=()=>{
	  setUnderLine(false);
  
	};
	

	const [selectedStyle, setSelectedStyle] = useState('style1');
	const [switched,setSwitched]=useState('Illustrations');

	const changeTabs=(value)=>{
		setSwitched(value);
		
		
	}

	const handleStyleChange = (styleId) => {
		
		setSelectedStyle(styleId);
		sendStyle(styleId);
		console.log(styleId);
		
	  };

	const data = [
	  {
		label: "Illustrations",
		value: "Illustrations"
	  },
	  {
		label: "Web Banner",
		value: "Web Banner"
		
	  },
	];

	const illustrations = [
		{ id: 1, label: 'Flat', imageSrc: './images/1.png' },
		{ id: 2, label: 'Isometric', imageSrc: './images/2.png' },
		{ id: 3, label: '3D', imageSrc: './images/3.jpg' },
		{ id: 4, label: 'Geometric', imageSrc: './images/4.png' },
	
	
		// Add more styles
	  ];

	  const banners = [
		{ id: 5, label: 'Illustrated Banner', imageSrc: './images/5.jpg' },
		{ id: 6, label: 'Abstract Banner', imageSrc: './images/6.jpg' },
		
		// Add more styles
	  ];
	
   
	return (
		<div className="flex flex-col justify-center items-center m-4" onMouseOver={handleMouseOver}
		onMouseOut={handleMouseOut}>


    <div className="mb-2">
      <p className={`block mb-2 text-2xl  m-2 p-6 ${underLine ? 'animate-text bg-gradient-to-r from-teal-400 via-purple-500 to-fuchsia-500 bg-clip-text text-transparent text-2xl font-bold ' : 'text-white'}`}>Select Your Style</p>
    </div>
	  <Tabs value="Illustrations" className="flex flex-col justify-center items-center ">
		<TabsHeader className="text-white w-42 flex items-center gap-2 font-semibold p-2 ">
		  {data.map(({ label, value }) => (
			<Tab  className= {`'text-white p-4 w-42 h-2 rounded-lg' ${switched===value?'bg-purple-500':'bg-zinc-700'}`} key={value} value={value} onClick={()=>changeTabs(value)}>
			  {label} 
			</Tab>
		  ))}
		</TabsHeader>
		<TabsBody>
		  {data.map(({ value }) => (
			<TabPanel key={value} value={value} className="">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  ">
				
  {switched === "Illustrations" &&
                  illustrations.map((style) => (
                    <CustomRadio
                      key={style.id}
                      id={style.id}
                      label={style.label}
                      imageSrc={style.imageSrc}
                      selected={selectedStyle === style.id}
                      onChange={() => handleStyleChange(style.id)}
                    />
                  ))}

                {switched === "Web Banner" &&
                  banners.map((style) => (
                    <CustomRadio
                      key={style.id}
                      id={style.id}
                      label={style.label}
                      imageSrc={style.imageSrc}
                      selected={selectedStyle === style.id}
                      onChange={() => handleStyleChange(style.id)}
                    />
                  ))}
	  </div>

			</TabPanel>
		  ))}
		</TabsBody>
	  </Tabs>
	  </div>
	);
  }

export default MyTabs;