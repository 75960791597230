import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<App />
<div className='border-t-2 border-gray-500 mt-4'>
<div className='m-4 flex justify-center items-center'>
  <p className='text-neutral-100'>Find me on</p>
 <a href="https://twitter.com/nandhugp3"> <img src="./images/twitter.png" alt="twitter" className='w-6 h-6'></img></a>
  
</div>
<div className='m-2 flex justify-center items-center'><p className='text-neutral-100'>Created by Nanda Kumar. All rights reserved</p></div>
</div>


  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
