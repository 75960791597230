import React from 'react';

const FullSizeImage = ({ image, onClose, onDownload }) => {


  const handleDownload = () => {
  // Check if a selected image exists

  const selectedImage=image;
  if (selectedImage) {
    // Use Fetch API to download the image
    fetch(selectedImage.fullSizeURL, {
      method: 'GET',
      mode: 'cors', // Use the 'cors' mode for cross-origin requests
    })
      .then((response) => {
        // Check if the response status is OK (200)
        if (response.status === 200) {
          return response.blob();
        } else {
          console.error('Failed to download image. Status:', response.status);
        }
      })
      .then((blob) => {
        if (blob) {
          // Create a URL for the blob
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element for downloading
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.download = 'image.jpg'; // Set the desired filename and extension

          // Trigger a click event on the anchor element to start the download
          downloadLink.click();

          // Clean up: Revoke the blob URL and remove the anchor element from the DOM
          window.URL.revokeObjectURL(url);
          document.body.removeChild(downloadLink);
        }
      })
      .catch((error) => {
        console.error('Error downloading the image:', error);
      });
  } else {
    // Handle the case where there is no selected image
    console.error('No image selected for download.');
  }
  };



    return (
      <div className="flex flex-col justify-center items-center p-4">
        <img src={image.fullSizeURL} alt="Full-size Image" className='mt-12 w-1/2 h-1/2' />
        <button
          className="mt-4 px-4 py-2 transition duration-500 ease-in-out bg-purple-500 hover:bg-fuchsia-500 hover:border-2 text-white rounded hover:bg-blue-600"
          onClick={handleDownload}
        >
          Download
        </button>
       
      </div>
    );
  };
  
export default FullSizeImage;
