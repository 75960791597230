import React from 'react';

const User = ({userName,credits}) => {
  return (
    <div>
      <header>
        <div className="user-email text-white m-2 text-base font-medium">
          {userName ? `You have ${credits} credits remaining ${userName}` : 'Sign up or log in with your Google account to get 3 credits  '}
        </div>
        {/* Other header content */}
      </header>
      {/* The rest of your home page content */}
    </div>
  );
};

export default User;
