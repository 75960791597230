import React, { useEffect, useState } from 'react';
import User from './User'; // Adjust the import path
import axios from 'axios';

const UserDetails = ({sendUserName}) => {
  const [userName, setUserName] = useState(null);
  const [credits, setCredits] = useState(null);
  useEffect(() => {
    // Create an Axios instance with credentials set to 'include'
    
  
    // Make an API request to fetch user data
    axios.get('https://api.vectora.space/api/user', {
      withCredentials: true
    })
      .then(response => {
        setUserName(response.data.user.name.givenName);
        setCredits(response.data.credits);
        sendUserName(response.data.user.name.givenName); 
        console.log(response);
        console.log(response.data.credits);
      })
      .catch(error => {
        // Handle the case when user data cannot be fetched (user is not logged in)
        setUserName(null); // Set userEmail to null to indicate a guest
      });
  }, []);


  return (
    <div>
      <User userName={userName} credits={credits} />
      {/* Other application content */}
    </div>
  );
};

export default UserDetails;
