import React from 'react';

const ImageList = ({ images, onImageClick }) => {
  return (
    <div className="flex flex-col justify-start items-center p-4">
      {images.map((image, index) => (
        <img
          key={index}
          src={image.thumbnailURL}
          alt={`Image ${index}`}
          className="h-32 max-w-xs m-2 cursor-pointer"
          onClick={() => onImageClick(image)}
        />
      ))}
    </div>
  );
};

export default ImageList;
